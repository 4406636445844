import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import {blog} from './Config/en'
import {Read} from './Shared/Api'

const ArticlesContainer = styled.section`
max-width: 1024px;
margin: auto;
  padding: 2rem 0;
  text-align: center;
`;

const ArticleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ArticleIcon = styled.div`
  font-size: 2rem;
  width: 8rem;
  aspect-ratio : 1 / 1;
  background-size: contain;
  background-image: url('landing.jpg');
`;

const ArticleTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const ArticleDescription = styled.p`
  font-size: 1rem;
`;

const ArticleLink = styled.a`
  font-size: 1rem;
`;

const Articles = () => {

  const [domain, Domain] = useState(11)
  const [loaded, Load] = useState(false)
  const [items , Items] = useState([])

  useEffect(()=>{        

    if(domain>0) {
      Read(`/blog/${domain}`, Items, Load)
      //console.log(items)
    }

},[domain])

  return (
    <ArticlesContainer id="articles">
      <h2>{blog.title}</h2>

      <div className="articles-grid">
        {items.map((item) => (
          <ArticleCard key={'Article-'+item.Id}>
            <ArticleIcon className="article-card" style={{backgroundImage: `url(${item.Image})`}} />
            <ArticleTitle>{item.Title}</ArticleTitle>
            <ArticleDescription>{item.Introduction}</ArticleDescription>
              <ArticleLink href={item.Link} target="_blank" rel="noopener noreferrer">
                {blog.read}
              </ArticleLink>
          </ArticleCard>
        ))}
      </div>

      <Link to="#about" className='link'>About</Link>
    </ArticlesContainer>
  );
};

export default Articles;
